<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <!--  Here comesTheTable-->
      <b-row>
        <b-col class="m-2">
          Preis des Zeitraums = {{ this.priceOfSelectedRange }} €
        </b-col>
        <b-col class="m-2">
          Preis für Follower = {{ this.priceOfFollowers }} €
        </b-col>
        <b-col class="m-2">
          Preis für Viewer = {{ this.priceOfViewer }} €
        </b-col>
        <b-col class="m-2">
          Preis für VOD/CLip = {{ this.priceOfVodClip }} €
        </b-col>
      </b-row>
      <b-row class="m-2">
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Zeitraum</label>
            <b-form-select
              id="perPageSelect"
              v-model="selectedTimeRange"
              size="sm"
              :options="availableDocs"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          sm="8"
          class="my-1"
        >
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    -- none --
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            style="min-height: 30vh"
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #cell(creationDate)="data">
              {{ getFormattedDate(data.item.creationDate) }}
            </template>

            <template #cell(type)="data">
              <b-badge :variant="getVariant(data.item.type)">
                {{ data.item.type }}
              </b-badge>
            </template>

            <template #cell(twitchname)="data">
              <b-link
                :href="'https://www.twitch.tv/' + data.item.twitchname"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.twitchname }}
              </b-link>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">-->
                <!--              <feather-icon icon="FileTextIcon" />-->
                <!--              <span class="align-middle ml-50">Details</span>-->
                <!--            </b-dropdown-item>-->

                <b-dropdown-item
                  @click="cancelOrder(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">CancelOrder</span>
                </b-dropdown-item>

              </b-dropdown>
            </template>
          </b-table>
        </b-col>

      </b-row>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ items.length }} to {{ items.length }} of {{ items.length }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <!--              totalUserss-->
            <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Login"
      cancel-title="Close"
      centered
      title="Login Form"
    >
      Change Twitchname for {{ selectedUser.twitchname }}
      <b-form>
        <b-form-group>
          <label>Twitchname</label>
          <b-form-input
            v-model="newTwitchname"
            type="text"
            placeholder="Twitchname"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="success"
          @click="saveTwitchname(selectedUser,newTwitchname), ok()"
        >
          Save
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'

/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BBadge,
  BLink,
  BRow,
  BProgress,
  BModal,
  VBModal,
  BForm,
  BProgressBar,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {Timestamp} from "firebase/firestore";


export default {
  components: {
    BTable,
    BAvatar,
    BProgress,
    BProgressBar,
    BCard,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BRow,
    BModal,
    BForm,
    BFormCheckbox,
    BFormInput,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      priceOfFollowers: 10,
      priceOfViewer: 10,
      priceOfVodClip: 10,
      selectedTimeRange: "AktuellerZeitraum",
      availableDocs: ["10_02-18_02"],
      showOlderOrders: false,
      twitchname: "",
      amount: 30,
      dripfeedSeconds: 60,
      dripfeedProStunde: 60,
      priceOfSelectedRange: 100,
      barProgress: 0,
      totalActiveViews: 0,
      waitingActive: false,
      selectedUser: { "id": 47, "geminosname": "Notnetic", "twitchname": "ducknetic", "license": "3437-3886-3296", "duration": -56, "durationLeft": -56, "durationTotal": 103, "plan": "Starter", "viewers": { "amountString": "20/15", "statusOfBadge": "light-success" }, "status": 2, "uid": "4Mh9ehv8nrYN8ABkLfinQI7I2yH3" },
      perPage: 20,
      pageOptions: [10, 20, 35, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      isjemkein: false,
      sortDesc: true,
      newTwitchname: "",
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Table-Id', sortable: true },
        { key: 'creationDate', label: 'Created' , sortable: true },
        {
          key: 'orderId', label: 'Order-Id', sortable: true },
        {
          key: 'type', label: 'Typ', sortable: true,
        },
        {
          key: 'quantity', label: 'Anzahl',sortable: true
        },
        {
          key: 'price', label: 'Preis',sortable: true
        },
        { key: 'twitchname', label: 'Twitch-Name', sortable: true },
        { key: 'actions', label: 'Actions', sortable: true },
      ],
      items: [
        {
          id: 1,
          // eslint-disable-next-line global-require
          geminosname: 'DisATest',
          twitchname: "Korrie O'Crevy",
          license: 'Nuclear Power Engineer',
          plan: 'kocrevy0@thetimes.co.uk',
          viewers: 'Krasnosilka',
          duration: '10',
          status: 2,
        },
      ],
      status: [{
        1: 'Inactive', 2: 'Active', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-danger', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    getFormattedDate2(oldDate) {
      console.log("Here")
      console.log(oldDate)
      return formatDate(oldDate.toDate(), { dateStyle: 'full', timeStyle: 'short' })
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.getAvailableDocs()
    this.resolveUserData()
    // this.refreshForCurrentViews()
    this.listenForUpdates()
    this.totalRows = this.items.length
  },
  watch: {
    selectedTimeRange() {
      this.listenForUpdates()
    },
    showOlderOrders() {
      this.listenForUpdates()
    },
    dripfeedSeconds() {
      this.dripfeedProStunde = 60*(60/this.dripfeedSeconds)
    },
    dripfeedProStunde() {
      this.dripfeedSeconds = (60/this.dripfeedProStunde)*60
    }
  },
  methods: {
    cancelOrder(item) {
      console.log(item)
      console.log(item.orderId)
      const sx = this.$firebase.firestore()
      let collectionQuery = sx.collection('followerFabrik').doc('orders').collection("oldOrderLists").doc(this.selectedTimeRange)
      if(this.selectedTimeRange === "AktuellerZeitraum") collectionQuery = sx.collection('followerFabrik').doc('orders')
      collectionQuery.get().then(async result => {
        let orderlist = result.data().orderList
        console.log(orderlist)
        let selectedOrder = orderlist[orderlist.findIndex(order => order.id === item.orderId)]
        console.log(selectedOrder)
        selectedOrder.canceled = true
        orderlist[orderlist.findIndex(order => order.id === item.orderId)] = selectedOrder
        await collectionQuery.set({
          orderList: orderlist
        }, {merge: true})
        this.listenForUpdates()
      })

    },
    getVariant(itemType) {
      if(itemType === "Canceled") return "light-danger"
      return "light-success"
    },
    resolveUserData() {
      let userData = getUserData()
      this.isjemkein= userData.id === 'towa5MUabmfPHflpRoxMVCrmDaj2';
    },
    getAvailableDocs() {
      const sx = this.$firebase.firestore()
      let newDocs = []
      sx.collection('followerFabrik').doc("orders").collection("oldOrderLists").get().then(async result => {
        await result.forEach(singleResult => {
          console.log(singleResult.id)
          newDocs.push(singleResult.id)
        })
        newDocs.push("AktuellerZeitraum")
        this.availableDocs = newDocs
      })
    },
    getPrice(order) {
      if(order.canceled === true) return 0
      function ParseFloat(str,val) {
        str = str.toString();
        str = str.slice(0, (str.indexOf(".")) + val + 1);
        return Number(str);
      }
      if(order.type === "vodClip") {
        let possibleValues = [100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000]
        let views = {
          "100": "0.25",
          "200": "0.4",
          "500": "0.8",
          "1000": "1.6",
          "2000": "3.2",
          "5000": "6.5",
          "10000": "12",
          "20000": "23",
          "50000": "40",
        }
        possibleValues = possibleValues.reverse()
        let multiplier = 1
        if(!(possibleValues.includes(parseInt(order.quantity)))) {
          let closest = possibleValues.find(numb => numb <= parseInt(order.quantity));
          multiplier = parseInt(order.quantity)/closest
          console.log(`Setting new multiplier ${multiplier} for ${order.quantity} id ${order.id}`);
          order.quantity = closest
        }
        return ParseFloat(parseFloat(views[order.quantity.toString()])*multiplier, 2)
      }
      if(order.price !== undefined ) return order.price
      if(order.type === "follower" || order.type === "followers") {
        let possibleValues = [10,30, 50, 100, 150, 200, 400, 500, 1000, 2000, 5000, 10000]

        let follower = {
          "10": "0.25",
          "30": "0.6",
          "50": "0.8",
          "100": "1.2",
          "150": "1.6",
          "200": "1.8",
          "400": "1.8",
          "500": "2.5",
          "1000": "4",
          "2000": "7",
          "5000": "13",
          "10000": "22",
        }
        try {
          possibleValues = possibleValues.reverse()
          let multiplier = 1
          if(!(possibleValues.includes(parseInt(order.quantity)))) {
            let closest = possibleValues.find(numb => numb <= parseInt(order.quantity));
            multiplier = parseInt(order.quantity)/closest
            console.log(`Setting new multiplier ${multiplier} for ${order.quantity} id ${order.id}`);

            order.quantity = closest
          }
          return ParseFloat(parseFloat(follower[order.quantity.toString()])*multiplier, 2)

        } catch (e) {
          console.log(e)
          return "ERROR"
        }
      }
      if(order.type === "viewer" || order.type === "viewers") {
        let possibleValues = [5,10, 15, 25, 40, 50, 75, 100, 200, 300, 350, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000]
        possibleValues = possibleValues.reverse()
        let multiplier = 1
        if(!(possibleValues.includes(parseInt(order.quantity)))) {
          let closest = possibleValues.find(numb => numb <= parseInt(order.quantity));
          multiplier = parseInt(order.quantity)/closest
          console.log(`Setting new multiplier ${multiplier} for ${order.quantity} id ${order.id}`);

          order.quantity = closest
        }
        let viewerPackages = { "Monthly": {
            "5": "6.5",
            "10": "9",
            "15": "11.5",
            "25": "16",
            "40": "18",
            "50": "19",
            "75": "27",
            "100": "35",
            "200": "65",
            "300": "95",
            "350": "110",
            "400": "125",
            "500": "155",
            "600": "185",
            "700": "215",
            "800": "245",
            "900": "275",
            "1000": "305",
            "1500": "480",
            "2000": "655",
          },
          "Weekly": {
            "5": "3.5",
            "10": "4.5",
            "15": "6.5",
            "25": "7.5",
            "50": "9.5",
            "75": "13.5",
            "100": "17.5",
            "200": "32.5",
            "300": "47.5",
            "400": "62.5",
            "500": "77.5",
            "600": "92.5",
            "700": "107.5",
            "800": "122.5",
            "900": "137.5",
            "1000": "152.5",
            "1500": "240",
            "2000": "327.5",
          },
          "Daily": {
            "5": "1",
            "10": "1.75",
            "20":"3",
            "15": "2.5",
            "25": "3",
            "50": "4",
            "75": "5.5",
            "100": "8",
            "200": "15",
            "300": "22",
            "400": "28",
            "500": "35",
            "600": "42",
            "700": "49",
            "800": "56",
            "900": "63",
            "1000": "69",
            "1500": "109",
            "2000": "149",
          }}
        let duration = "None"
        if(order.durationInMinutes == "1440") duration = "Daily"
        if(order.durationInMinutes == "10080") duration = "Weekly"
        if(order.durationInMinutes == "43200") duration = "Monthly"
        if(1441 < parseInt(order.durationInMinutes)&&  parseInt(order.durationInMinutes) < 10079) {
          duration = "Daily"
          console.log("MORE THAN DAILY")

          console.log(order.twitchname)
          console.log(order.durationInMinutes)
          return ParseFloat(((parseFloat(viewerPackages[duration][order.quantity.toString()]) * (order.durationInMinutes/1440))*multiplier),2)
        }
        if(10081 < parseInt(order.durationInMinutes)&&  parseInt(order.durationInMinutes) < 43199) {
          duration = "Weekly"
          console.log("MORE THAN WEEKLY")
          console.log(order.twitchname)
          console.log(order.durationInMinutes)
          return ParseFloat(((parseFloat(viewerPackages[duration][order.quantity.toString()]) * (order.durationInMinutes/10080))*multiplier),2)
        }
        if(parseInt(order.durationInMinutes) > 43200) {
          duration = "Monthly"
          console.log("MORE THAN MONTHLY")
          console.log(order.durationInMinutes/43200)
          console.log(order.quantity)
          console.log(Math.round(viewerPackages[duration][order.quantity.toString()]) * (order.durationInMinutes/43200))
          return ParseFloat((parseFloat(viewerPackages[duration][order.quantity.toString()]) * (order.durationInMinutes/43200)*multiplier),2)
        }
        try {
          return ParseFloat(viewerPackages[duration][order.quantity.toString()]*multiplier,2)
        } catch (e) {
          console.log(e)
          console.log(order)
          return "ERROR"
        }
      }
      console.log(e)
      return "ERROR"
    },
    listenForUpdates() {
      this.priceOfSelectedRange = 20
      this.priceOfFollowers = 10
      this.priceOfViewer = 10
      const sx = this.$firebase.firestore()
      let collectionQuery = sx.collection('followerFabrik').doc('orders').collection("oldOrderLists").doc(this.selectedTimeRange)
      console.log("Test1")
      if(this.selectedTimeRange === "AktuellerZeitraum") collectionQuery = sx.collection('followerFabrik').doc('orders')
      let errorcatched = false
      collectionQuery.get().then(result => {
        this.items = []
        let id = 0
        this.totalActiveViews = 0
          result.data().orderList.forEach(order => {
            try {


            let price = this.getPrice(order)
            if(price === "ERROR"){
              errorcatched = true
              console.log("ERROR")
              console.log(order)
              if(order.price === undefined || order.price === null) {
                errorcatched = true
                console.log(`Error catched for order ${order.id}`)
                price = 100000
              } else {
                price = order.price
              }
            }
            if(price === undefined) {
              price = order.price
              if(price === undefined) price = 1000
              errorcatched = true
              console.log(`Error catched for order ${order.id}`)


            }
            let newObject = {
              id: id,
              // eslint-disable-next-line global-require
              type: order.type,
              orderId: order.id,
              creationDate: order.creationDate,
              quantity: order.quantity,
              twitchname: order.twitchname,
              price,
            }
            this.priceOfSelectedRange += parseFloat(price)
            if(order.type === "viewer" || order.type === "viewers") {
              this.priceOfViewer += parseFloat(price)
            }
            if(order.type === "follower" || order.type === "followers") {
              this.priceOfFollowers += parseFloat(price)
            }
            if(order.type === "vodClip") {
              this.priceOfVodClip += parseFloat(price)
            }
              if(order.canceled) newObject.type = "Canceled"
            this.items.push(newObject)
            id += 1
            } catch (e) {
              this.$swal({
                title: 'Error',
                text: 'Error in order',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
        })
        this.priceOfSelectedRange = Math.round(this.priceOfSelectedRange)
      if(errorcatched) {
        this.$swal({
          title: 'Error',
          text: 'Error in one of the Orders',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
        console.log(this.items)
    },
    extendPackage(item) {
      const sx = this.$firebase.firestore()
      const obj = {
        TotalDays: item.durationTotal + 1,
      }
      // sx.collection('userTest').doc(item.id).update({ test: obj })
      sx.collection('User').doc(item.uid).set({ License: obj }, { merge: true })
    },
    getFormattedDate(newDate){
      try {
        return newDate.toDate().toLocaleString()
      } catch (e) {
        return "None"
      }
    },
    getTwitchLink(user) {
      return `https://www.twtich.tv/${user.twitchname}`
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
